.accordion-button {
  background-color: #f9f8f8 !important;
  color: #6750a4 !important;
}
.accordion-button:focus {
  background-color: #f9f8f8 !important;
  border-color: #6750a4 !important;
}
.meeting-details-heading {
  font-weight: bolder;
  margin-bottom: 10px;
  margin-top: 10px;
}
.circle {
  background-color: #6750a4;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    font-family: Poppins-Regular !important;
}
